export const DEFAULT_IMAGE_DIMENSIONS = 160;

export function formatCloudinaryImageUrl({
  url,
  width,
  height,
  strategy = 'fill',
  gravity,
}: {
  url: string;
  gravity?: string;
  width?: string;
  height?: string;
  strategy?: 'crop' | 'fill' | 'pad'; // See https://cloudinary.com/documentation/transformation_reference
}) {
  const cloudinaryParts = [];
  if (!url) return '';
  if (!height && !width) return url;

  if (width) {
    cloudinaryParts.push(`w_${width}`);
  }

  if (height) {
    cloudinaryParts.push(`h_${height}`);
  }

  if (strategy) {
    cloudinaryParts.push(`c_${strategy}`);
  }

  if (gravity) {
    cloudinaryParts.push(`g_${gravity}`);
  }

  const parts = url.split('/upload');
  const midPart = `/upload/${cloudinaryParts.join(',')}`;
  const reunion = parts.join(midPart);

  return reunion;
}
