// IETF BCP 47
export enum Locale {
  CA = 'ca', // Catalan
  CA_ES = 'ca-ES',
  DE = 'de', // German
  DE_DE = 'de-DE',
  EN_GB = 'en-GB', // English (Great Britain)
  EN_UK = 'en-UK', // English (United Kingdom)
  EN_US = 'en-US',
  EN = 'en', // English
  ES = 'es', // Spanish
  ES_ES = 'es-ES',
  FR = 'fr', // French
  FR_FR = 'fr-FR',
  IT = 'it', // Italian
  IT_IT = 'it-IT',
  NL = 'nl', // Dutch
  NL_NL = 'nl-NL',
  PT_PT = 'pt-PT', // Portuguese
  SV = 'sv', // Swedish
  SV_SE = 'sv-SE',
}
