import styled from '@emotion/styled';
import Overlay from '@lafourchette/react-chili/dist/cjs/components/Molecules/Overlay';
import { FOR_MOBILE_ONLY } from '@/utils/emotion-utils';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 100;
`;

export const StyledOverlay = styled(Overlay)`
  background-color: ${({ theme }) => theme.customization.colors.background};
  color: ${({ theme }) => theme.customization.colors.text.default};
  @media (min-width: 800px) {
    max-width: 740px;
  }
  button > svg {
    color: ${({ theme }) => theme.customization.colors.text.default};
  }
  ${FOR_MOBILE_ONLY} {
    margin: 0 ${({ theme }) => theme.space.xl};
    height: fit-content;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: ${({ theme }) => theme.radii.m};
  }
`;

export const ModalHeader = styled.div`
  background-color: ${({ theme }) => theme.customization.colors.background};
  color: ${({ theme }) => theme.customization.colors.text.default};
  padding: ${({ theme }) => theme.space.m};
  text-align: center;
`;

export const ModalBody = styled.div`
  padding: ${({ theme }) => theme.space.m};
`;
