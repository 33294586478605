import { getTrackingServicesConfiguration } from '@/utils/captureException/utils';
import { datadogRum } from '@datadog/browser-rum';

export const captureException = (
  error: Error | unknown,
  tags: Record<string, string> | undefined = undefined,
  extras: Record<string, unknown> | undefined = undefined,
) => {
  const { datadogEnabled } = getTrackingServicesConfiguration();
  if (datadogEnabled) {
    datadogRum.addError(error, { ...tags, ...extras });
  }
};
