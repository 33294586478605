import { useEffect, useState } from 'react';
import { mobileDesktopBreakpoint } from '@/utils/emotion-utils';

const useViewport = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(window.innerWidth > mobileDesktopBreakpoint);

    const handleWindowResize = () => setIsDesktop(window.innerWidth > mobileDesktopBreakpoint);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { isDesktop };
};

export default useViewport;
