export type DataDogConfig = {
  applicationId: string;
  clientToken: string;
  environment: string;
  site: string;
  version: string;
  isEnabled: boolean;
  sessionSamplingPercentage: number;
  sessionReplaySamplingPercentage: number;
  userDataMaskingEnabled: boolean;
  isDevModeEnabled: boolean;
  allowedTracingDomains: string[];
  allowedTracingPaths: string[];
  traceSampleRate: number;
  proxyURL: string;
};

export enum TRACKING_CONSENT {
  GRANTED = 'granted',
  NOT_GRANTED = 'not-granted',
}

export enum PRIVACY_LEVEL {
  MASK = 'mask',
  MASK_USER_INPUT = 'mask-user-input',
}
