import { noop } from 'lodash';
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

type ErrorContextProps = {
  errorMessage: string | null;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  closeError: () => void;
  onAction: (() => void) | null;
  setOnAction: Dispatch<SetStateAction<(() => void) | null>>;
};

export const ErrorContext = createContext<ErrorContextProps>({
  errorMessage: null,
  setErrorMessage: noop,
  closeError: noop,
  onAction: noop,
  setOnAction: noop,
});

export const ErrorProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [onAction, setOnAction] = useState<(() => void) | null>(null);

  return (
    <ErrorContext.Provider
      value={{
        errorMessage,
        setErrorMessage,
        closeError: () => {
          setOnAction(null);
          setErrorMessage(null);
        },
        setOnAction,
        onAction,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export function useError() {
  return useContext(ErrorContext);
}
