import * as S from './ErrorLayout.styles';

export type ErrorLayoutProps = {
  Illustration: React.FC;
  title: string;
  message: string;
  action?: {
    label?: string;
    icon?: React.ReactNode;
    handleAction: () => void;
  };
};

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({ Illustration, title, message, action }) => {
  return (
    <S.Layout>
      <S.TextActionDiv>
        <S.TextContent>
          {title ? <S.Title variant="title3">{title}</S.Title> : null}
          {message ? <S.Message>{message}</S.Message> : null}
        </S.TextContent>
        {action && (
          <S.ActionButtonWrapper>
            <S.ActionButton data-testid="error-action-button" onClick={action.handleAction}>
              {action.icon ? <S.ActionButtonIconWrapper>{action.icon}</S.ActionButtonIconWrapper> : null}
              {action.label ? action.label : null}
            </S.ActionButton>
          </S.ActionButtonWrapper>
        )}
      </S.TextActionDiv>
      <S.ImageDiv>
        <S.ImageWrapper>
          <Illustration />
        </S.ImageWrapper>
      </S.ImageDiv>
    </S.Layout>
  );
};
