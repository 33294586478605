import {
  DesignKitFragment,
  GetRestaurantFragment,
  WidgetSettingFragment,
  WidgetTypeEnum,
} from '@/graphql/types.generated';
import { ThemeProvider } from '@emotion/react';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { buildTheme } from '@/utils/theme/theme-utils';
import { ConfigurationItemKeys } from '@/types/config';
import { getConfigurationValue } from '@/utils/configuration-utils';

export type WidgetConfig = {
  designKit: DesignKitFragment | null;
  facebookIntegration: { pixelId: string } | null;
  restaurant: GetRestaurantFragment | null;
  settings: WidgetSettingFragment | null;
};

type RestaurantThemeProviderProps = {
  children: ReactNode;
  widgetConfig?: WidgetConfig;
};

type WidgetConfigContextProps = {
  widgetConfig: WidgetConfig | null;
  isWidgetCustomizable: boolean;
  isTfBrandingDisplayed: boolean;
};

export const WidgetConfigContext = createContext<WidgetConfigContextProps>({
  widgetConfig: null,
  isWidgetCustomizable: false,
  isTfBrandingDisplayed: false,
});

export const useWidgetConfig = () => {
  return useContext(WidgetConfigContext);
};

export const WidgetConfigProvider = ({ children, widgetConfig }: RestaurantThemeProviderProps) => {
  const designKit = widgetConfig?.designKit ?? null;
  const providerValue = useMemo(() => {
    const isWidgetCustomizable = widgetConfig?.settings?.type === WidgetTypeEnum.White;
    const isTfBrandingDisplayed =
      getConfigurationValue(widgetConfig?.settings, ConfigurationItemKeys.TF_BRANDING, 'false') === 'true';

    const customConfig = {
      ...widgetConfig,
      designKit: widgetConfig?.designKit || null,
      facebookIntegration: widgetConfig?.facebookIntegration || null,
      settings: widgetConfig?.settings || null,
      restaurant: widgetConfig?.restaurant || null,
    };

    return { widgetConfig: customConfig, isWidgetCustomizable, isTfBrandingDisplayed };
  }, [widgetConfig]);

  const customTheme = useMemo(() => buildTheme({ designKit }), [designKit]);

  return (
    <WidgetConfigContext.Provider value={providerValue}>
      <ThemeProvider theme={customTheme}>{children}</ThemeProvider>
    </WidgetConfigContext.Provider>
  );
};
