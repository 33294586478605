import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import ChiliRadio from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/Radio';
import { InputHTMLAttributes } from 'react';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import styled from '@emotion/styled';

export const SingleRadio = styled(ChiliRadio)<{ disabled?: boolean }>`
  & + .radioFake {
    background-color: ${({ theme }) => theme.customization.colors.checkbox.background.unchecked} !important;
    border-color: ${({ theme }) => theme.customization.colors.checkbox.border.default} !important;
    transition: border-color ${({ theme }) => theme.duration.m};
  }

  & ~ .radioLabel {
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.customization.colors.checkbox.border.disabled
        : theme.customization.colors.checkbox.border.default} !important;
    font-family: ${({ theme }) => theme.customization.fonts.body};
  }

  &:checked + .radioFake {
    background-color: ${({ theme }) => theme.customization.colors.checkbox.background.checked} !important;
    border-color: ${({ theme }) => theme.customization.colors.checkbox.border.checked} !important;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.customization.colors.background} inset;
  }

  &:not(:disabled):not(:checked):hover + .radioFake {
    border-color: ${({ theme }) => theme.customization.colors.checkbox.border.hover} !important;
    transition: border-color 0s;
  }

  &:disabled + .radioFake {
    border-color: ${({ theme }) => theme.customization.colors.checkbox.border.disabled} !important;
  }
`;

type ChiliRadioProps<T> = {
  prefix: string;
  values: [T, string][];
};

type RadioProps<T extends FieldValues, U> = UseControllerProps<T> &
  InputHTMLAttributes<HTMLInputElement> &
  ChiliRadioProps<U>;

export const Radio = <T extends Record<string, unknown>, U extends string>({
  prefix,
  values,
  ...props
}: RadioProps<T, U>) => {
  const {
    field: { onChange, onBlur, name },
  } = useController(props);

  return (
    <Flex className={props.className}>
      {values.map(([key, value]) => (
        <SingleRadio
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          key={key}
          id={`${prefix}-${key}`}
          data-testid={`${prefix}-${key}`}
          value={key}
        >
          {value}
        </SingleRadio>
      ))}
    </Flex>
  );
};
