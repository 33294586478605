import { yearInSeconds } from '@/utils/date-utils';
import cookie from 'cookie';

const COOKIE_DEFAULT_OPTIONS = {
  maxAge: yearInSeconds,
  path: '/',
};

export function serializeCookie(name: string, value: string, options?: cookie.CookieSerializeOptions) {
  const optionsWithDefault = { ...COOKIE_DEFAULT_OPTIONS, ...options };
  return cookie.serialize(name, value, optionsWithDefault);
}

export function getCookie(rawCookieHeader: string, name: string): string | undefined {
  return cookie.parse(rawCookieHeader)[name];
}
