import React, { ErrorInfo, ReactNode } from 'react';
import * as S from './ErrorBoundary.styles';
import { captureException } from '@/utils/captureException';

export type ErrorBoundaryProps = {
  handleGoBack?: () => void;
  children: ReactNode;
  module: string;
};

type ErrorBoundaryState = {
  hasError: boolean;
};
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    captureException(error, { module: this.props.module, error_type: 'ErrorBoundary' }, { react_info: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <S.TechnicalError handleGoBack={this.props.handleGoBack} />;
    }
    return this.props.children;
  }
}
