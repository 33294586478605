import { Locale } from '@/types/locale';

// Mapping: input locale -> output locale.
// This mapping should be defined by the Product.
export const localesMapping: Partial<Record<Locale, Locale>> = {
  [Locale.EN_GB]: Locale.EN_UK,
};

export const MAPPING_BACKEND_ACCEPT_LANGUAGE = {
  [Locale.CA]: Locale.CA_ES,
  [Locale.EN]: Locale.EN_US,
  [Locale.EN_GB]: Locale.EN_GB,
  [Locale.DE]: Locale.DE_DE,
  [Locale.ES]: Locale.ES_ES,
  [Locale.FR]: Locale.FR_FR,
  [Locale.IT]: Locale.IT_IT,
  [Locale.PT_PT]: Locale.PT_PT,
  [Locale.SV]: Locale.SV_SE,
  [Locale.NL]: Locale.NL_NL,
};
