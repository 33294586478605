import { DefaultPrivacyLevel, RumInitConfiguration } from '@datadog/browser-rum';
import getConfig from 'next/config';
import { isServer } from 'utils/isServer';
import { isbot } from 'isbot';

import { DataDogConfig, PRIVACY_LEVEL, TRACKING_CONSENT } from './Datadog.types';

export const getDataDogConfig = (): DataDogConfig => {
  return getConfig().publicRuntimeConfig.datadog;
};

export const transformUserPrivacyRequirements = (maskUserData: boolean): DefaultPrivacyLevel => {
  return maskUserData ? PRIVACY_LEVEL.MASK : PRIVACY_LEVEL.MASK_USER_INPUT;
};
const generateAllowedUrls = (allowedTracingDomains: string[], allowedTracingPaths: string[]): string[] =>
  allowedTracingDomains.flatMap((allowedTrackingDomain) =>
    allowedTracingPaths.map((allowedTrackingPath) => allowedTrackingDomain.concat(allowedTrackingPath)),
  );

export const shouldInitializeDataDog = (dataDogConfig: DataDogConfig): boolean => {
  return !isServer() && dataDogConfig.isEnabled;
};

export const evaluateConditionalSampleRate = (sessionSamplingPercentage: number): number => {
  return isbot(navigator.userAgent) ? 0 : sessionSamplingPercentage;
};

export const transformDataDogConfig = (dataDogConfig: DataDogConfig): RumInitConfiguration => {
  const {
    applicationId,
    clientToken,
    site,
    version,
    environment,
    sessionReplaySamplingPercentage,
    sessionSamplingPercentage,
    userDataMaskingEnabled,
    allowedTracingDomains,
    allowedTracingPaths,
    traceSampleRate,
    proxyURL,
  } = dataDogConfig;
  return {
    allowedTracingUrls: generateAllowedUrls(allowedTracingDomains, allowedTracingPaths),
    applicationId,
    clientToken,
    defaultPrivacyLevel: transformUserPrivacyRequirements(userDataMaskingEnabled),
    env: environment,
    service: 'widget-front',
    sessionReplaySampleRate: sessionReplaySamplingPercentage,
    sessionSampleRate: evaluateConditionalSampleRate(sessionSamplingPercentage),
    site,
    traceSampleRate: traceSampleRate,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    trackingConsent: TRACKING_CONSENT.GRANTED,
    proxy: proxyURL,
    version,
  };
};
