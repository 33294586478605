import styled from '@emotion/styled';
import Text from '@lafourchette/react-chili/dist/cjs/components/Atoms/Text';

export type TextVariant =
  | 'body'
  | 'small'
  | 'bodyLight'
  | 'bodyRegular'
  | 'edito'
  | 'smallLight'
  | 'smallRegular'
  | 'microLight'
  | 'microRegular'
  | 'micro';

export const StyledText = styled(Text)`
  font-family: ${({ theme }) => theme.customization.fonts.body};
`;
