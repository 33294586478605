import styled from '@emotion/styled';
import { FOR_TABLET_AND_MORE } from '@/utils/emotion-utils';
import { Text } from '@/components/Text/Text';
import { motion } from 'framer-motion';
import { Modal } from '@/components/Modal/Modal';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import { Radio } from '@/components/ReactHookForm/Radio';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import Grid from '@lafourchette/react-chili/dist/cjs/components/Atoms/Grid';
import { ComponentProps } from 'react';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.l};
`;

export const FormContainer = styled.div`
  padding-bottom: ${px2rem(100)};
`;

export const NoticeVoucherContainer = styled(Flex)`
  gap: ${({ theme }) => theme.space.m};
  flex-direction: column;
`;

export const PoliciesContainer = styled(Box)`
  margin-top: ${({ theme }) => theme.space.l};
`;

export const CheckboxContainer = styled(Box)`
  margin-top: ${({ theme }) => theme.space.s};
`;

export const InputContainer = styled((props: ComponentProps<typeof Grid>) => (
  <Grid gridGap="m" marginTop="1rem" {...props} />
))`
  & > label {
    grid-template-rows: ${px2rem(20)} ${px2rem(26)} ${px2rem(2)};
  }
  grid-template-columns: repeat(auto-fit);
  ${FOR_TABLET_AND_MORE} {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }
`;

export const MealDurationContainer = styled(Box)`
  margin-top: ${({ theme }) => theme.space.l};
`;

export const ErrorContainer = styled(motion.div)`
  position: fixed;
  right: 8px;
  bottom: 40px;
  z-index: 2;
`;

export const Terms = styled(Text)`
  color: ${({ theme }) => theme.customization.colors.text.default};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  font-family: ${({ theme }) => theme.customization.fonts.body};
  margin-bottom: ${({ theme }) => theme.space.l};
`;

export const LinkStyle = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const LegalModal = styled(Modal)`
  min-width: 325px;
`;

export const CivilityContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 5px ${({ theme }) => theme.space.m};
  align-items: stretch;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.space.m};

  ${FOR_TABLET_AND_MORE} {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledCivilityRadios = styled(Radio)`
  display: flex;
  justify-content: space-between;
  ${FOR_TABLET_AND_MORE} {
    label {
      margin-right: ${({ theme }) => theme.space.l};
    }
  }
` as typeof Radio;

export const CivilityLeftCol = styled.div`
  flex: 1;
`;
export const CivilityRightCol = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
`;
