import { Button } from '@/components/Button/Button';
import { Heading } from '@/components/Heading/Heading';
import { Text } from '@/components/Text/Text';
import { FOR_MOBILE_ONLY } from '@/utils/emotion-utils';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';

export const Layout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  gap: ${({ theme }) => theme.space.xl};
  padding: ${({ theme }) => theme.space.m};
  ${FOR_MOBILE_ONLY} {
    flex-direction: column-reverse;
  }
`;

export const ImageDiv = styled.div`
  flex: 1;
  margin: auto;
  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 250px;
  margin: auto;
`;

export const TextActionDiv = styled.div`
  flex: 1;
`;

export const TextContent = styled.div`
  grid-area: textContent;
  color: ${({ theme }) => theme.customization.colors.text.default};
  font-family: ${({ theme }) => theme.customization.fonts.body};
`;

export const Title = styled(Heading)`
  color: ${({ theme }) => theme.customization.colors.text.default};
  font-family: ${({ theme }) => theme.customization.fonts.heading};
`;

export const Message = styled((props: ComponentProps<typeof Text>) => (
  <Text as="p" display="block" variant="bodyRegular" {...props} />
))`
  color: ${({ theme }) => theme.customization.colors.text.default};
  font-family: ${({ theme }) => theme.customization.fonts.body};
`;

export const ActionButtonWrapper = styled.div``;

export const ActionButton = styled(Button)`
  width: 100%;
`;

export const ActionButtonIconWrapper = styled.span`
  margin-right: ${({ theme }) => theme.space.s};
`;
