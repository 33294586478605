import { useTheme } from '@emotion/react';

export const ShatteredWineGlassIllustration = () => {
  const theme = useTheme();

  const glass = theme.customization.colors.svg.primary;
  const wine = theme.customization.colors.svg.secondary;

  return (
    <svg viewBox="0 0 223 136" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="SRP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="4.1.2.-Filters---Server-error" transform="translate(-68.000000, -178.000000)">
          <g id="serverErrorIllustration" transform="translate(68.000000, 178.000000)">
            <g id="flaque" transform="translate(0.000000, 39.000000)">
              <path
                d="M200.8335,50.6346 C200.0035,51.4246 206.5835,55.3546 180.7735,62.5746 C180.1135,62.7646 179.4535,62.9446 178.7935,63.1046 L178.7835,63.1046 L177.1635,63.5246 C175.5635,63.9246 173.9735,64.2846 172.3835,64.6146 C169.1135,65.3146 165.8735,65.8746 162.7235,66.3346 C143.8535,69.1046 128.1835,68.2346 127.6835,68.7346 C127.0235,69.3846 133.7635,68.2046 133.7635,75.0246 C133.7635,81.8446 112.7235,81.8446 112.2335,82.3646 C111.7435,82.8946 117.1635,84.3346 114.5335,90.1046 C111.9035,95.8746 76.8935,96.7846 58.1535,95.7446 C39.4135,94.6946 44.1835,91.5446 46.4835,89.8446 C48.7835,88.1346 69.4935,84.7246 71.1435,77.6446 C72.7835,70.5646 61.7735,68.3346 46.3135,67.6846 C30.8635,67.0246 15.9135,63.4846 14.7635,61.5246 C13.6035,59.5546 13.6035,54.1746 35.4735,51.9546 C57.3335,49.7246 62.5935,43.0346 61.7735,38.8346 C61.0635,35.2446 52.6535,31.6546 42.2035,30.4446 C40.4435,30.2446 38.6335,30.1046 36.7835,30.0546 C28.4535,29.8046 14.9035,30.7946 6.7835,27.9946 C2.4135,26.5046 -0.3865,23.9046 0.0435,19.4346 C0.4835,14.9246 7.2135,11.3846 16.0635,8.6746 C29.3935,4.5746 47.5035,2.3546 56.1035,1.5146 C57.9635,1.3246 59.3835,1.2046 60.2035,1.1446 C104.3435,-2.0654 109.9335,2.2646 110.2635,4.4946 C110.5835,6.7146 102.0435,8.8146 103.6835,14.5846 C105.3235,20.3546 140.9935,17.0746 154.6435,15.7646 C168.2835,14.4546 173.5435,18.1246 171.9035,20.2246 C170.2535,22.3246 151.1935,21.5346 149.8735,30.7146 C149.1235,35.9746 162.5735,36.3646 176.0835,35.4946 C179.2235,35.2946 182.3735,35.0246 185.3335,34.7346 C186.8935,34.5746 188.4035,34.4146 189.8435,34.2546 L191.3535,34.0846 C195.2735,33.6346 198.5135,33.2046 200.5035,32.9446 C210.5235,31.6246 220.5535,35.1646 222.1935,41.5946 C223.8435,48.0146 201.6535,49.8546 200.8335,50.6346"
                id="Fill-1"
                fill={wine}
              ></path>
              <path
                d="M56.1034,1.5146 L42.2034,30.4446 C40.4434,30.2446 38.6334,30.1046 36.7834,30.0546 C28.4534,29.8046 14.9034,30.7946 6.7834,27.9946 L16.0634,8.6746 C29.3934,4.5746 47.5034,2.3546 56.1034,1.5146"
                id="Fill-4"
                fill={glass}
              ></path>
              <path
                d="M185.3335,34.7346 L172.3835,64.6146 C169.1135,65.3146 165.8735,65.8746 162.7235,66.3346 L176.0835,35.4946 C179.2235,35.2946 182.3735,35.0246 185.3335,34.7346"
                id="Fill-6"
                fill={glass}
              ></path>
              <polygon
                id="Fill-8"
                fill={glass}
                points="177.1635 63.5246 189.8435 34.2546 191.3535 34.0846 178.7935 63.1046 178.7835 63.1046"
              ></polygon>
            </g>
            <g
              id="piece01"
              transform="translate(30.000000, 76.000000)"
              stroke={glass}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0.75"
            >
              <path
                d="M12.4152,0.37495 C12.4152,0.37495 7.0212,6.44495 5.5052,6.56295 C3.9882,6.68195 0.3752,1.90095 0.3752,1.90095 C0.3752,1.90095 4.0762,5.56895 12.4152,0.37495 Z"
                id="Stroke-1"
              ></path>
              <path
                d="M12.4152,0.37495 L12.8992,1.70795 C12.8992,1.70795 8.3602,6.96195 5.9432,7.76895 L5.5052,6.56295"
                id="Stroke-3"
              ></path>
              <path
                d="M5.9431,7.76875 C5.9431,7.76875 2.8531,6.87275 1.0341,3.71375 L0.3751,1.90075"
                id="Stroke-5"
              ></path>
            </g>
            <g
              id="piece02"
              transform="translate(25.000000, 19.000000)"
              stroke={glass}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            >
              <path
                d="M0.75,4.0443 C0.75,4.0443 16.17,8.7943 19.335,8.2943 C22.5,7.7943 26.306,0.7503 26.306,0.7503 C26.306,0.7503 21.5,6.5443 0.75,4.0443 Z"
                id="Stroke-1"
              ></path>
              <path
                d="M0.75,4.0443 L0.75,5.7883 C0.75,5.7883 13.837,9.9543 19.335,9.8713 L19.335,8.2943"
                id="Stroke-3"
              ></path>
              <path d="M19.335,9.8715 C19.335,9.8715 24.945,7.5385 26.306,3.1215 L26.306,0.7505" id="Stroke-5"></path>
            </g>
            <g
              id="glass"
              transform="translate(145.500000, 45.500000) rotate(2.000000) translate(-145.500000, -45.500000) translate(93.000000, 21.000000)"
              stroke={glass}
              strokeWidth="2"
            >
              <path
                d="M12.107,0.7599 C12.317,-0.8351 52.76,-2.6051 50.679,25.7829 C48.652,53.4399 14.177,43.9829 6.145,39.8449"
                id="Stroke-1"
              ></path>
              <path
                d="M104.5151,30.5127 C103.6031,41.0187 96.9901,49.0247 89.7461,48.3957 C82.5021,47.7667 77.3691,38.7397 78.2821,28.2347 C79.1941,17.7287 85.8061,9.7227 93.0501,10.3517 C100.2941,10.9807 105.4271,20.0067 104.5151,30.5127 Z"
                id="Stroke-3"
              ></path>
              <path
                d="M11.3636,6.1585 C11.3636,6.1585 2.9846,15.0215 2.2626,15.5315 C1.5406,16.0425 0.5076,16.5265 0.2786,16.7665 C0.0496,17.0075 -0.0554,22.4185 0.0296,22.6345 C0.1146,22.8505 3.9616,25.3735 3.9956,25.5855 C4.0286,25.7965 1.6606,28.4575 1.6996,28.6175 C1.7376,28.7775 7.6716,33.6705 7.6646,34.3475 C7.6576,35.0245 6.1246,39.4785 6.1446,39.8445 C6.1646,40.2115 18.8996,35.2195 18.9776,34.9135 C19.0566,34.6075 16.8606,28.6835 17.1466,28.3955 C17.4316,28.1075 23.0856,24.2205 23.1126,23.9095 C23.1396,23.5995 16.8846,20.6065 16.9116,20.2965 C16.9386,19.9855 24.0516,11.8995 24.1956,11.4425 C24.3396,10.9865 21.8806,0.2695 21.6826,0.1475 C21.4846,0.0265 12.2696,-0.0695 11.1176,1.1855 C9.9666,2.4405 6.4676,9.1215 6.4676,9.1215"
                id="Stroke-5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M87.2276,35.1861 C87.2356,35.1891 87.2456,35.1901 87.2536,35.1911 C87.3586,35.2171 87.4636,35.2331 87.5696,35.2411 C89.4216,35.3811 91.1066,33.0641 91.3326,30.0711 C91.5576,27.0741 90.2386,24.5321 88.3866,24.3921 C88.2806,24.3841 88.1746,24.3841 88.0676,24.3951 C88.0596,24.3941 88.0486,24.3931 88.0406,24.3951"
                id="Stroke-7"
              ></path>
              <path d="M49.8825,21.4919 C56.5745,22.2649 66.0925,28.9379 88.3865,24.3919" id="Stroke-9"></path>
              <path d="M49.0653,32.3409 C55.7973,32.5789 66.2083,27.4069 87.5693,35.2409" id="Stroke-11"></path>
            </g>
            <g
              id="piece03"
              transform="translate(72.000000, 0.000000)"
              stroke={glass}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d="M0.5,5.23915 C0.5,5.23915 13.038,1.37715 15.612,1.78315 C18.185,2.19015 21.28,7.91815 21.28,7.91815 C21.28,7.91815 17.372,3.20615 0.5,5.23915 Z"
                id="Stroke-1"
              ></path>
              <path
                d="M0.5,5.23915 L0.5,3.82115 C0.5,3.82115 11.141,0.43315 15.612,0.50115 L15.612,1.78315"
                id="Stroke-3"
              ></path>
              <path
                d="M15.6119,0.50095 C15.6119,0.50095 20.1729,2.39795 21.2799,5.98995 L21.2799,7.91795"
                id="Stroke-5"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
