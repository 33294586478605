import styled from '@emotion/styled';
import Heading from '@lafourchette/react-chili/dist/cjs/components/Atoms/Heading';

export type HeadingVariant = 'headline' | 'title1' | 'title2' | 'title3' | 'title3Light' | 'title4';

export const StyledHeading = styled(Heading)`
  flex-wrap: wrap;
  text-align: center;
  font-family: ${({ theme }) => theme.customization.fonts.heading};
  font-weight: bold;
`;
