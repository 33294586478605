import * as S from './Text.styles';
import ChiliText from '@lafourchette/react-chili/dist/cjs/components/Atoms/Text';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { ComponentProps } from 'react';

type TextProps = Omit<ComponentProps<typeof ChiliText>, 'variant' | 'disabled'> & {
  variant?: S.TextVariant;
};

export const Text = ({ variant = 'body', ...props }: TextProps) => {
  const { isWidgetCustomizable } = useWidgetConfig();

  if (isWidgetCustomizable) {
    return <S.StyledText variant={variant} {...props} />;
  }

  return <ChiliText variant={variant} {...props} />;
};
