import { HexColor, HSL, RGB, StringHSL } from '@/types/color';

export function expandHexCode(hexCode: string): string {
  let hex = hexCode;
  if (hex.length == 4) {
    hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }
  return hex;
}

function hslToRgb(hsl: HSL): RGB {
  const [hue, s, l] = hsl;
  const saturation = s / 100;
  const luminosity = l / 100;

  const chroma = (1 - Math.abs(2 * luminosity - 1)) * saturation;
  const x = chroma * (1 - Math.abs(((hue / 60) % 2) - 1));
  const lightness = luminosity - chroma / 2;

  let [red, green, blue] = [0, 0, 0];

  if (0 <= hue && hue < 60) {
    red = chroma;
    green = x;
  } else if (60 <= hue && hue < 120) {
    red = x;
    green = chroma;
  } else if (120 <= hue && hue < 180) {
    green = chroma;
    blue = x;
  } else if (180 <= hue && hue < 240) {
    green = x;
    blue = chroma;
  } else if (240 <= hue && hue < 300) {
    red = x;
    blue = chroma;
  } else if (300 <= hue && hue < 360) {
    red = chroma;
    blue = x;
  }

  red = Math.round((red + lightness) * 255);
  green = Math.round((green + lightness) * 255);
  blue = Math.round((blue + lightness) * 255);

  return [red, green, blue];
}

export function hslToHex(hsl: string): string {
  const separator = hsl.indexOf(',') > -1 ? ',' : ' ';
  const splitHsl = hsl.substring(4).split(')')[0].split(separator);
  const hue = parseInt(splitHsl[0], 10);
  const saturation = parseInt(splitHsl[1].substring(0, splitHsl[1].length - 1), 10);
  const luminosity = parseInt(splitHsl[2].substring(0, splitHsl[2].length - 1), 10);

  const [red, green, blue] = hslToRgb([hue, saturation, luminosity]);

  const hexRed = red.toString(16).padStart(2, '0');
  const hexGreen = green.toString(16).padStart(2, '0');
  const hexBlue = blue.toString(16).padStart(2, '0');

  return `#${hexRed}${hexGreen}${hexBlue}`.toUpperCase();
}

function hexToRgb(hexInput: string): RGB {
  const hex = expandHexCode(hexInput);

  const red = parseInt(`${hex[1]}${hex[2]}`, 16);
  const green = parseInt(`${hex[3]}${hex[4]}`, 16);
  const blue = parseInt(`${hex[5]}${hex[6]}`, 16);

  return [red, green, blue];
}

export function hexToHsl(hex: string): StringHSL;
export function hexToHsl(hex: string, asArray: true): HSL;
export function hexToHsl(hex: string, asArray?: true) {
  let [red, green, blue] = hexToRgb(hex);

  red /= 255;
  green /= 255;
  blue /= 255;

  const cmin = Math.min(red, green, blue);
  const cmax = Math.max(red, green, blue);
  const delta = cmax - cmin;
  let hue = 0;
  let saturation = 0;
  let luminosity = 0;

  if (delta == 0) hue = 0;
  else if (cmax == red) hue = ((green - blue) / delta) % 6;
  else if (cmax == green) hue = (blue - red) / delta + 2;
  else hue = (red - green) / delta + 4;

  hue = Math.round(hue * 60);

  if (hue < 0) hue += 360;

  luminosity = (cmax + cmin) / 2;
  saturation = delta == 0 ? 0 : delta / (1 - Math.abs(2 * luminosity - 1));
  saturation = +(saturation * 100).toFixed(0);
  luminosity = +(luminosity * 100).toFixed(0);

  if (asArray) {
    return [hue, saturation, luminosity];
  }

  return `hsl(${hue}, ${saturation}%, ${luminosity}%)`;
}

export function modifyHexColor(color: string, modifier: { saturation?: number; luminosity?: number }): string {
  const [hue, saturation, luminosity] = hexToHsl(color, true);

  const modifiedSaturation = Math.max(0, Math.min(100, saturation + (modifier.saturation ?? 0)));
  const modifiedLuminosity = Math.max(0, Math.min(100, luminosity + (modifier.luminosity ?? 0)));

  return hslToHex(`hsl(${hue}, ${modifiedSaturation}%, ${modifiedLuminosity}%)`);
}

export const BLACK = '#000000';
export const WHITE = '#FFFFFF';

function luminance(rgb: RGB) {
  const [r, g, b] = rgb.map((v) => {
    let colorParam = v / 255;
    colorParam = colorParam > 0.03928 ? ((colorParam + 0.055) / 1.055) ** 2.4 : (colorParam /= 12.92);
    return colorParam;
  });
  return r * 0.2126 + g * 0.7152 + b * 0.0722;
}

function contrast(foregroundColor: RGB, backgroundColor: RGB) {
  const foregroundLuminance = luminance(foregroundColor);
  const backgroundLuminance = luminance(backgroundColor);
  return backgroundLuminance > foregroundLuminance
    ? (backgroundLuminance + 0.05) / (foregroundLuminance + 0.05)
    : (foregroundLuminance + 0.05) / (backgroundLuminance + 0.05);
}

const isHexColor = (color: string): color is HexColor => {
  return color.startsWith('#');
};

/**
 * Output a contrast ratio on a scale from 1 (low contrast) to 21 (high contrast)
 */
export function getContrastRatioBetween(color1: string, color2: string) {
  if (!(isHexColor(color1) && isHexColor(color2))) {
    throw 'Colors given as parameter must be hexadecimal';
  }

  const color1AsRGB = hexToRgb(color1);
  const color2AsRGB = hexToRgb(color2);
  const contrasted = contrast(color1AsRGB, color2AsRGB);

  return contrasted;
}
