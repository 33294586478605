import { datadogRum } from '@datadog/browser-rum';
import { getDataDogConfig, shouldInitializeDataDog, transformDataDogConfig } from '@/services/datadog/Datadog.utils';

export const initializeDatadog = (): void => {
  const dataDogConfig = getDataDogConfig();
  if (shouldInitializeDataDog(dataDogConfig) && datadogRum.getInitConfiguration() === undefined) {
    datadogRum.init(transformDataDogConfig(dataDogConfig));
    datadogRum.startSessionReplayRecording();
  }
};
