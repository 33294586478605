import { AnimatePresence } from 'framer-motion';
import { useError } from '@/contexts/ErrorProvider/ErrorProvider';
import Button from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/Button';
import { useIntl } from 'react-intl';
import Snackbar from '@lafourchette/react-chili/dist/cjs/components/Molecules/Snackbar';
import * as S from '@/components/ContactForm/ContactForm.styles';

export const ErrorToast = () => {
  const { errorMessage, closeError, onAction } = useError();
  const intl = useIntl();

  return (
    <AnimatePresence>
      {errorMessage && (
        <S.ErrorContainer
          key="modal"
          initial={{ translateY: 100 }}
          animate={{ translateY: 0 }}
          exit={{ translateY: 100 }}
        >
          <Snackbar
            variant="error"
            onClose={closeError}
            renderActionLink={
              onAction
                ? () => (
                    <Button variant="compactAlert" as="button" onClick={onAction}>
                      {intl.formatMessage({
                        id: 'tf_widget_snackbar_submit',
                        defaultMessage: 'Retry',
                      })}
                    </Button>
                  )
                : undefined
            }
          >
            {errorMessage}
          </Snackbar>
        </S.ErrorContainer>
      )}
    </AnimatePresence>
  );
};
