import { Locale } from '@/types/locale';
import { localesMapping, MAPPING_BACKEND_ACCEPT_LANGUAGE } from './localesMapping';

/**
 * Imports the only translation file needed.
 */
export const getTranslations = async (locale: string, fallbackLocale: string) => {
  let imported, importedLocale: string;
  try {
    imported = await import(`../../locales/${locale}.json`);
    importedLocale = locale;
  } catch (_) {
    try {
      imported = await import(`../../locales/${fallbackLocale}.json`);
      importedLocale = fallbackLocale;
    } catch (_) {
      imported = await import('@/locales/en.json');
      importedLocale = Locale.EN;
    }
  }
  return {
    messages: imported,
    messagesLocale: importedLocale,
  };
};

/**
 * Get the supported locale for the given value
 * Order: supportedLocale -> mappedLocale -> baseLocale -> similarLocale -> null.
 */
export const getSupportedLocale = (supportedLocales: Locale[], value = '') => {
  if (supportedLocales.includes(value as Locale)) {
    return value;
  }
  const mappedLocale = getMappedLocale(value);
  if (mappedLocale && supportedLocales.includes(mappedLocale)) {
    return mappedLocale;
  }
  const baseLocale = getBaseLocale(value);
  if (baseLocale && supportedLocales.includes(baseLocale as Locale)) {
    return baseLocale;
  }
  const similarLocales = supportedLocales.filter((supportedLocale) => {
    return baseLocale === getBaseLocale(supportedLocale);
  });
  if (similarLocales.length) {
    return similarLocales[0];
  }
  return null;
};

export const getBackendAcceptLanguage = (locale: Locale) => {
  return MAPPING_BACKEND_ACCEPT_LANGUAGE[locale as keyof typeof MAPPING_BACKEND_ACCEPT_LANGUAGE] || Locale.EN_US;
};

/**
 * Get the base locale from the given locale (eg: 'fr-FR' -> 'fr').
 */
export const getBaseLocale = (locale = '') => {
  if (locale.includes('-')) {
    return locale.split('-')[0];
  }
  if (locale.includes('_')) {
    return locale.split('_')[0];
  }
  return locale;
};

/**
 * Get the mapped locale for the given locale.
 */
const getMappedLocale = (locale: string) => {
  return localesMapping[locale as keyof typeof localesMapping] || null;
};

export const parseAcceptedLanguage = (rawAcceptedLanguage = '') => {
  return rawAcceptedLanguage.split(',')[0].split(';')[0];
};
