import { captureException } from '@/utils/captureException';
import { format, parse, add } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

type FormatOption = {
  onlyDate?: boolean;
  withSecond?: boolean;
};

export const twentyFourHourInMinutes = 1440;

export const yearInSeconds = 31536000; // 365 * 24 * 60 * 60

export const formatForGraphQl = (date: Date): string => {
  return format(date, 'yyyy-MM-dd');
};

export const maxDate = (d1: Date, d2: Date) => (d1 > d2 ? d1 : d2);

export const minDate = (d1: Date, d2: Date) => (d1 < d2 ? d1 : d2);

/**
 * Converts a given timeslot (in minutes) into a time string (format "hh:mm") formatted with the given separator.
 * Example: 750 --> 12:30
 * @param {number} timeslot - The timeslot (in minutes) to convert into a formatted time string.
 * @param {string} [separator=':'] - The string separator to use (e.g: ":", "-", ...), default ":".
 * @returns {string} The formatted time string.
 */
export const timeslotToTimeHHMM = (timeslot: number, separator: string = ':' as string): string => {
  const nightHour = timeslot >= twentyFourHourInMinutes ? timeslot - twentyFourHourInMinutes : timeslot;
  const hours = `${Math.floor(nightHour / 60)}`.padStart(2, '0');
  const minutes = `${nightHour % 60}`.padStart(2, '0');
  return `${hours}${separator}${minutes}`;
};

/**
 * Converts a given date into a timeslot (in minutes).
 * Example: new Date('2023-03-30 12:00') --> 720
 * @param {Date} date - The date to convert into a time slot.
 * @returns {number} The time slot (in minutes).
 */
export const dateToTimeslot = (date: Date): number => {
  return date.getHours() * 60 + date.getMinutes();
};

/**
 * Convert a given time slot (in minutes) into a date object.
 * Example: 720 --> new Date('1970-01-01T12:00')
 * @param {number} timeSlot - The time slot in minutes to convert into a date.
 * @returns {date} The converted date (1970-01-01TXX:XX).
 */
export const timeSlotToDate = (timeSlot: number): Date => {
  // eslint-disable-next-line no-restricted-syntax
  return new Date(`1970-01-01T${timeslotToTimeHHMM(timeSlot)}`);
};

/**
 * Converts a given date into a time string (format "hh:mm").
 * @param {Date} date - The date to convert into a time string ("hh:mm").
 * @returns {string} The time string ("hh:mm").
 */
export const dateToHHMM = (date: Date): string => {
  return timeslotToTimeHHMM(dateToTimeslot(date));
};

export const dateTimeToDate = (dateTime: string, format = 'yyyy-MM-dd HH:mm:ss') => {
  // eslint-disable-next-line no-restricted-syntax
  return parse(dateTime, format, new Date());
};

export const formatDate = (date: Date, formatPattern = 'yyyy-MM-dd HH:mm:ss') => {
  return format(date, formatPattern);
};

/**
 * Extract the date string from a dateTime string (example: input: '2023-03-20 12:30:00'; output: '2023-03-20').
 * @param {string} dateTime - The dateTime string to use.
 * @returns {string} The extracted date string.
 */
export const dateTimeToDateString = (dateTime: string): string => {
  if (dateTime.length < 10 || !dateTime.match(/\d{4}\-\d{2}-\d{2}/)) {
    throw new Error('Invalid dateTime string');
  }
  return dateTime.slice(0, 10);
};

export const addOneDayFromDateTime = (datetime: string) => {
  return add(dateTimeToDate(datetime, 'yyyy-MM-dd'), { days: 1 });
};

const adaptBookingDateWithTimeslot = (date: string, timeslot: number) => {
  if (timeslot >= twentyFourHourInMinutes) {
    return addOneDayFromDateTime(date);
  }
  return dateTimeToDate(date, 'yyyy-MM-dd');
};

export const formatDateWithTimeslot = (date: string, timeslot: number, { onlyDate, withSecond }: FormatOption = {}) => {
  const adaptedDate = adaptBookingDateWithTimeslot(date, timeslot);

  if (onlyDate) {
    return formatForGraphQl(adaptedDate);
  }

  return `${formatForGraphQl(adaptedDate)} ${timeslotToTimeHHMM(timeslot)}${withSecond ? ':00' : ''}`;
};

export const dateToZonedDate = (date: Date, timezone?: string) => {
  if (timezone) {
    return toZonedTime(date, timezone);
  }
  return date;
};

/**
 * Converts a date string to a date.
 * @param {string} dateStr - The date string (example: "2023-03-20") with the timezone offset since we use "new Date()".
 * @returns {Date} The date with the timezone offset.
 */
export const dateStrToDate = (dateStr: string): Date => {
  // eslint-disable-next-line no-restricted-syntax
  return new Date(dateStr);
};

/**
 * Converts a date string into a date with no time (we set the time at '00:00:00').
 * @param {Date} dateStr - The date.
 * @returns {Date} The date with the time set to '00:00:00'.
 */
export const dateStrToDateNoTZ = (dateStr: string): Date => {
  return dateTimeToDate(`${dateStr.slice(0, 10)}T00:00:00`, "yyyy-MM-dd'T'HH:mm:ss");
};

/**
 * Returns the same date, without reference.
 * @param {Date} date - The date.
 * @returns {Date} The same date (no reference).
 */
export const dateCopy = (date: Date): Date => {
  // eslint-disable-next-line no-restricted-syntax
  return new Date(date.getTime());
};

/**
 * Converts a given date string and timeslot to a zoned date and time.
 * @param dateString - The date string in the format 'yyyy-MM-dd'.
 * @param timeslot - The timeslot as a string.
 * @param timezone - (Optional) The timezone to convert the date and time to.
 * @returns The timezoned date as a Date object.
 */
export const getZonedDateTimeFromDateAndTimeslot = (dateString: string, timeslot: string, timezone?: string) => {
  try {
    const timeString = timeslotToTimeHHMM(Number(timeslot));
    const dateTimeString = `${dateString} ${timeString}:00`;

    const outputDate = timezone ? fromZonedTime(dateTimeString, timezone) : dateTimeToDate(dateTimeString);

    if (!!outputDate.toISOString()) {
      return outputDate;
    }
    return null;
  } catch (error) {
    captureException(new Error(`Error while computing the timezoned date: ${error}`));
    return null;
  }
};
