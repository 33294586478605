import { createContext, useContext } from 'react';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { dateStrToDate, dateToZonedDate } from '@/utils/date-utils';

type RestaurantDateContextProps = {
  restaurantDate: Date;
};

export const RestaurantDateContext = createContext<RestaurantDateContextProps | undefined>(undefined);

export function useRestaurantDate() {
  const context = useContext(RestaurantDateContext);
  if (!context) {
    throw new Error('useRestaurantDate must be used within a RestaurantDateProvider');
  }
  return context;
}

type RestaurantDateProviderProps = {
  serverDateUTC: string;
  children?: React.ReactNode;
};

export const RestaurantDateProvider = ({ serverDateUTC, children }: RestaurantDateProviderProps) => {
  const { widgetConfig } = useWidgetConfig();
  const restaurantDate = dateToZonedDate(dateStrToDate(serverDateUTC), widgetConfig?.restaurant?.timezone);
  return (
    <RestaurantDateContext.Provider value={{ restaurantDate: restaurantDate }}>
      {children}
    </RestaurantDateContext.Provider>
  );
};
