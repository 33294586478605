export enum ConfigurationItemKeys {
  BOOKING_BUTTON_BACKGROUND_COLOR = 'bookingButton_backgroundColor',
  BOOKING_BUTTON_TEXT_COLOR = 'bookingButton_textColor',
  WIDGET_LOGO = 'widgetLogo',
  WIDGET_BANNER_IMAGE = 'widgetBannerImage',
  TF_BRANDING = 'tfBranding',
  INTEGRATION_THANK_YOU_PAGE_ENABLE = 'thankYouPageEnabled',
  INTEGRATION_THANK_YOU_PAGE_URL = 'thankYouPageUrl',
  IS_RESTAURANT_LOGO_DISPLAYED = 'isRestaurantLogoDisplayed',
  IS_RESTAURANT_NAME_DISPLAYED = 'isRestaurantNameDisplayed',
}

export enum TranslationItemKeys {
  TRANSLATION_BUTTON_LEGEND = 'tf_restaurantWidgetService_book_submit_button',
  TRANSLATION_TERMS_CONDITIONS = 'tf_restaurantWidgetService_terms_conditions',
  TRANSLATION_DATA_COLLECTION = 'tf_restaurantWidgetService_data_collection',
  TRANSLATION_ACTIVE_MENTION = 'tf_restaurantWidgetService_active_mention',
  TRANSLATION_MAIN_MENTION = 'tf_restaurantWidgetService_main_mention',
  TRANSLATION_SUPP_MENTION = 'tf_restaurantWidgetService_supp_mention',
}
