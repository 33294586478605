import { IconArrowRefresh } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { useIntl } from 'react-intl';
import { ErrorLayout } from '@/components/ErrorLayout/ErrorLayout';
import { ShatteredWineGlassIllustration } from './ShatteredWineGlass.illustration';

export type TechnicalErrorProps = {
  handleGoBack?: () => void;
};

export const TechnicalError: React.FC<TechnicalErrorProps> = ({ handleGoBack }) => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'tf_widget_technicalError_title',
    defaultMessage: 'Something went wrong.',
  });
  const message = intl.formatMessage({
    id: 'tf_widget_technicalError_message',
    defaultMessage: 'Try refreshing this page or contact the restaurant if the problem persists.',
  });
  const actionLabel = intl.formatMessage({
    id: 'tf_widget_technicalError_back',
    defaultMessage: 'Reset widget',
  });
  return (
    <ErrorLayout
      Illustration={ShatteredWineGlassIllustration}
      title={title}
      message={message}
      action={
        handleGoBack && {
          icon: <IconArrowRefresh />,
          label: actionLabel,
          handleAction: handleGoBack,
        }
      }
    />
  );
};
