export const desktopBreakpoint = 992;
export const mobileDesktopBreakpoint = 600;
export const mobileLandscapeWidth = 480;
export const minimumNormalSize = 360;

export const FOR_DESKTOP = `@media (min-width: ${desktopBreakpoint}px)`;
export const FOR_DESKTOP_ONLY = `@media (min-width: ${mobileDesktopBreakpoint}px)`;
export const FOR_TABLET_AND_MORE = `@media (min-width: ${mobileLandscapeWidth}px)`;
export const FOR_NORMAL_SIZE = `@media (min-width: ${minimumNormalSize}px)`;
export const FOR_XS_SIZE = `@media (max-width: ${minimumNormalSize - 1}px)`;
export const FOR_MOBILE_ONLY = `@media (max-width: ${mobileDesktopBreakpoint - 1}px)`;
export const BELOW_MOBILE_LANDSCAPE = `@media (max-width: ${mobileLandscapeWidth - 1}px)`;
