import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import Button from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/Button';
import { IconChevronRight } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';

export { IconChevronRight };

export type ButtonVariant = 'primary' | 'secondary' | 'ghost' | 'compact';
type ButtonStates = 'default' | 'hover' | 'pressed' | 'disabled' | 'focused';
type StyledButtonProps = {
  theme: Theme;
  variant: ButtonVariant;
  disabled: boolean;
};

const getBackgroundColor = ({ theme, variant }: StyledButtonProps, state: ButtonStates) => {
  return theme.customization.colors.cta[variant][state];
};

const getColor = ({ theme, variant }: StyledButtonProps, state: ButtonStates) => {
  return theme.customization.colors.text.onCta[variant][state];
};

const getBoxShadow = ({ theme, variant }: StyledButtonProps, state: ButtonStates) => {
  return theme.customization.colors.border[variant][state];
};

const getUnderline = ({ theme, variant }: StyledButtonProps, state: ButtonStates) => {
  return theme.customization.colors.underline[variant][state];
};

export const StyledButton = styled(Button)<{ variant: ButtonVariant; disabled: boolean }>`
  border-radius: 4px !important;
  transition:
    background-color ${({ theme }) => theme.duration.m},
    box-shadow ${({ theme }) => theme.duration.m},
    color ${({ theme }) => theme.duration.m};

  font-family: ${({ theme }) => theme.customization.fonts.body};
  background-color: ${({ isActive, ...args }) => getBackgroundColor(args, isActive ? 'focused' : 'default')} !important;
  box-shadow: ${({ isActive, ...args }) =>
    `inset 0 0 0 1px ${getBoxShadow(args, isActive ? 'focused' : 'default')}`} !important;
  color: ${({ isActive, ...args }) => getColor(args, isActive ? 'focused' : 'default')} !important;

  &:focus {
    background-color: ${({ ...args }) => getBackgroundColor(args, 'focused')} !important;
    box-shadow: ${({ ...args }) =>
      `inset 0 0 0 ${args.variant === 'compact' ? 1 : 4}px ${getBoxShadow(args, 'focused')}`} !important;
    text-decoration: ${({ ...args }) => `underline ${getUnderline(args, 'focused')}`} !important;
    color: ${({ ...args }) => getColor(args, 'focused')} !important;
  }

  &:not(:disabled):hover {
    background-color: ${({ ...args }) => getBackgroundColor(args, 'hover')} !important;
    box-shadow: ${({ ...args }) => `inset 0 0 0 1px ${getBoxShadow(args, 'hover')}`} !important;
    color: ${({ ...args }) => getColor(args, 'hover')} !important;
    text-decoration: ${({ ...args }) => `underline ${getUnderline(args, 'hover')}`} !important;
    transition:
      background-color 0s,
      box-shadow 0s,
      color 0s;
  }

  &:not(:disabled):active {
    background-color: ${({ ...args }) => getBackgroundColor(args, 'pressed')} !important;
    box-shadow: ${({ ...args }) => `inset 0 0 0 1px ${getBoxShadow(args, 'pressed')}`} !important;
    color: ${({ ...args }) => getColor(args, 'pressed')} !important;
    text-decoration: ${({ ...args }) => `underline ${getUnderline(args, 'pressed')}`} !important;
    transition:
      background-color 0s,
      box-shadow 0s,
      color 0s;
  }

  &:disabled {
    background-color: ${({ ...args }) => getBackgroundColor(args, 'disabled')} !important;
    box-shadow: inset 0 0 0 0px transparent !important;
    color: ${({ ...args }) => getColor(args, 'disabled')} !important;
  }

  :nth-of-type(n) {
    font-family: ${({ theme }) => theme.customization.fonts.body};
  }
`;
