import { Fonts } from '@/graphql/types.generated';
import getConfig from 'next/config';

export const DEFAULT_FONT = { fontName: 'RalewayX', isGoogleFont: false };

export function buildFontsGlobalStyle({ heading, body }: Fonts): string[] {
  const {
    publicRuntimeConfig: { baseFontsUrl },
  } = getConfig();
  const fonts = [];

  if (body?.isGoogleFont) {
    const sanitizedFontName = body.fontName.replaceAll(' ', '+');
    const bodyFontBaseUrl = `${baseFontsUrl}/${sanitizedFontName}/${sanitizedFontName}`;

    fonts.push(`
      @font-face {
        font-family: '${body.fontName}';
        src: url(${bodyFontBaseUrl}.woff2) format('woff2'), local(RalewayX);
      }
    `);
    fonts.push(
      `@font-face {
        font-family: '${body.fontName}';
        src: url(${bodyFontBaseUrl}-bold.woff2) format('woff2'), url(${bodyFontBaseUrl}.woff2) format('woff2'), local(RalewayX);
        font-weight: bold;
      }`,
    );
  }

  if (heading?.isGoogleFont) {
    const sanitizedFontName = heading.fontName?.replaceAll(' ', '+');
    const headingFontBaseUrl = `${baseFontsUrl}/${sanitizedFontName}/${sanitizedFontName}`;

    fonts.push(`
      @font-face {
        font-family: '${heading.fontName}';
        src: url(${headingFontBaseUrl}.woff2) format('woff2'), local(RalewayX);
      }
    `);
    fonts.push(
      `@font-face {
        font-family: '${heading.fontName}';
        src: url(${headingFontBaseUrl}-bold.woff2) format('woff2'), url(${headingFontBaseUrl}.woff2) format('woff2'), local(RalewayX);
        font-weight: bold;
      }`,
    );
  }
  return fonts;
}
