import { ConfigurationItemKeys } from '@/types/config';
import { WidgetSettingFragment } from '@/graphql/types.generated';

export const getConfigurationValue = (
  widgetSetting: WidgetSettingFragment | undefined | null,
  key: ConfigurationItemKeys,
  defaultValue?: string,
) => {
  const defaultOut = defaultValue || '';
  if (widgetSetting?.configuration) {
    const configurationItem = widgetSetting.configuration.find((item) => item.key === key);
    return configurationItem?.value ?? defaultOut;
  }
  return defaultOut;
};
