import { DesignKitFragment } from '@/graphql/types.generated';
import { Theme } from '@emotion/react';
import { chili2023, defaultTheme } from '@lafourchette/design-system-theming/themes';
import { BLACK, getContrastRatioBetween, hexToHsl, hslToHex, modifyHexColor, WHITE } from './color-utils';
import { DEFAULT_FONT } from './font-utils';

export function getModifier(value: number, modifier: number | string): number {
  if (typeof modifier === 'number') {
    return modifier;
  }
  if (!modifier.match(/%/)) {
    return Number(modifier) || 0;
  }
  const [, operator, percentage] = parsePercentageString(modifier);
  if (operator === '-') {
    // Note: percentage applies on the floor value (example: value 25 at -50% is base 25 --> -13)
    const base = value;
    return -Math.floor((base * Number(percentage)) / 100);
  }
  // Note: percentage applies on the ceil base reserved value (example: value 25 at +50% is base 75 --> +38).
  const base = 100 - value;
  return Math.ceil((base * Number(percentage)) / 100);
}

function parsePercentageString(input: string): Array<string> {
  return input.split(/(-?)(\d*)(%)?/);
}

export function getFontColor(backgroundHexaColor: string): string {
  return getContrastRatioBetween(backgroundHexaColor, BLACK) > getContrastRatioBetween(backgroundHexaColor, WHITE)
    ? BLACK
    : WHITE;
}

export function isLowContrast(color1: string, color2: string, limit: number): boolean {
  return getContrastRatioBetween(color1, color2) < limit;
}

export function isLowColorContrast(color1: string, color2: string): boolean {
  return getContrastRatioBetween(color1, color2) < 3;
}

export function isLowTextContrast(colorText: string, colorBackground: string): boolean {
  return getContrastRatioBetween(colorText, colorBackground) < 4.5;
}

export function colorShift(color: string, modifiers: { luminosity: string | number; saturation?: string | number }) {
  const [, saturation, luminosity] = hexToHsl(color, true);
  const luminosityModifier = modifiers.luminosity ? getModifier(luminosity, modifiers.luminosity) : 0;
  const saturationModifier = modifiers.saturation ? getModifier(saturation, modifiers.saturation) : 0;
  return modifyHexColor(color, { luminosity: luminosityModifier, saturation: saturationModifier });
}

export function buildTheme({ designKit }: { designKit: DesignKitFragment | null }): Theme {
  const accent = designKit?.colors?.accent ?? hslToHex(chili2023.colors.brandPrimary[500]); // #00666C
  const background = designKit?.colors?.background ?? '#F9FAFA';

  const lightenBackground = getContrastRatioBetween(background, WHITE) > getContrastRatioBetween(background, BLACK);

  // Background colors.
  const background100 = background; // xxs #F9FAFA
  const background200 = colorShift(background, { luminosity: lightenBackground ? '6%' : '-6%' }); // xs #ECEFEF
  const background300 = colorShift(background, { luminosity: lightenBackground ? '14%' : '-14%' }); // s #D5DCDC
  const background400 = colorShift(background, { luminosity: lightenBackground ? '37%' : '-37%' }); // m #95A7A7
  const background500 = colorShift(background, { luminosity: lightenBackground ? '61%' : '-61%' }); // l #5A6C6C
  const background600 = colorShift(background, { luminosity: lightenBackground ? '83%' : '-83%' }); // xl #272F2F
  const backgroundDisabled = colorShift(background, {
    luminosity: lightenBackground ? '20%' : '-20%',
    saturation: '-70%',
  }); // #C8CBCB
  const backgroundTransparent = 'transparent';

  // Grey colors (transparent).
  const grey100 = `${getFontColor(background)}0A`; // 4% opacity #F5F5F5
  const grey200 = `${getFontColor(background)}12`; // 7% opacity #EDEDED
  const grey300 = `${getFontColor(background)}1F`; // 13% opacity #DEDEDE
  const grey400 = `${getFontColor(background)}33`; // 20% opacity #CCCCCC
  const grey500 = `${getFontColor(background)}4D`; // 30% opacity #B3B3B3
  const grey600 = `${getFontColor(background)}61`; // 38% opacity #9E9E9E

  const isAccentColorCloseToWhite = isLowColorContrast(accent, WHITE);
  const isAccentColorCloseToBlack = isLowColorContrast(accent, BLACK);
  const isLowContrastAccentBackground = isLowColorContrast(accent, background);
  const isVeryLowContrastAccentBackground = isLowContrast(accent, background, 1.2);
  const isBackgroundColorCloseToBlack = isLowColorContrast(background, BLACK);

  // Primary colors.
  const accent100 = colorShift(accent, { luminosity: isAccentColorCloseToWhite ? '-93%' : '93%', saturation: '-68%' }); // #EEF6F6
  const accent200 = colorShift(accent, { luminosity: isAccentColorCloseToWhite ? '-75%' : '75%', saturation: '-70%' }); // #BDDBD8
  const accent300 = colorShift(accent, { luminosity: isAccentColorCloseToWhite ? '-50%' : '50%', saturation: '-70%' }); // #7AB8B1
  const accent400 = colorShift(accent, { luminosity: isAccentColorCloseToWhite ? '-15%' : '15%', saturation: '-4%' }); // #03A090
  const accent500 = accent; // #00666C
  const accent600 = colorShift(accent, { luminosity: isAccentColorCloseToBlack ? '29%' : '-5%', saturation: '-50%' }); // #004D45
  const accent700 = colorShift(accent, { luminosity: isAccentColorCloseToBlack ? '40%' : '-20%', saturation: '-70%' }); // #003D37
  const accent800 = colorShift(accent, { luminosity: isAccentColorCloseToBlack ? '50%' : '-38%', saturation: '-70%' }); // #00332E
  const accent900 = colorShift(accent, { luminosity: isAccentColorCloseToBlack ? '60%' : '-45%', saturation: '-70%' }); // #002925

  // Transparent colors.
  const accent100T = `${accent500}12`; // 7% opacity, near #EEF6F6
  const accent200T = `${accent500}40`; // 25% opacity, near #BDDBD8
  const accent300T = `${accent500}80`; // 50% opacity, near #7AB8B1
  const accent400T = `${accent500}CC`; // 80% opacity, near #03A090

  return {
    ...defaultTheme,
    customization: {
      colors: {
        background: background, // Alias of "background100".
        background100: background100,
        background200: background200,
        background300: background300,
        background400: background400,
        background500: background500,
        background600: background600,
        grey100: grey100,
        grey200: grey200,
        grey300: grey300,
        grey400: grey400,
        grey500: grey500,
        grey600: grey600,
        accent: accent500, // Alias of "accent500".
        accent100: accent100,
        accent200: accent200,
        accent300: accent300,
        accent400: accent400,
        accent500: accent500,
        accent600: accent600,
        accent700: accent700,
        accent800: accent800,
        accent900: accent900,
        accent100T: accent100T,
        accent200T: accent200T,
        accent300T: accent300T,
        accent400T: accent400T,
        border: {
          primary: {
            default: backgroundTransparent,
            disabled: backgroundTransparent,
            focused: accent200,
            hover: backgroundTransparent,
            pressed: backgroundTransparent,
          },
          secondary: {
            default: grey300,
            disabled: backgroundTransparent,
            focused: isLowContrastAccentBackground ? accent300 : accent400T,
            hover: isLowContrastAccentBackground ? accent700 : accent500,
            pressed: isLowContrastAccentBackground ? grey600 : accent600,
          },
          ghost: {
            default: backgroundTransparent,
            disabled: backgroundTransparent,
            focused: isLowContrastAccentBackground ? accent300 : accent400T,
            hover: backgroundTransparent,
            pressed: backgroundTransparent,
          },
          compact: {
            default: backgroundTransparent,
            disabled: backgroundTransparent,
            focused: isLowContrastAccentBackground ? accent300 : accent400T,
            hover: backgroundTransparent,
            pressed: backgroundTransparent,
          },
          line: backgroundDisabled,
        },
        underline: {
          primary: {
            default: backgroundTransparent,
            disabled: backgroundTransparent,
            focused: backgroundTransparent,
            hover: backgroundTransparent,
            pressed: backgroundTransparent,
          },
          secondary: {
            default: backgroundTransparent,
            disabled: backgroundTransparent,
            focused: backgroundTransparent,
            hover: backgroundTransparent,
            pressed: backgroundTransparent,
          },
          ghost: {
            default: backgroundTransparent,
            disabled: backgroundTransparent,
            focused: backgroundTransparent,
            hover: backgroundTransparent,
            pressed: backgroundTransparent,
          },
          compact: {
            default: backgroundTransparent,
            disabled: backgroundTransparent,
            focused: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
            hover: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
            pressed: backgroundTransparent,
          },
        },
        cta: {
          primary: {
            default: isVeryLowContrastAccentBackground ? grey300 : accent500,
            disabled: grey200,
            focused: isVeryLowContrastAccentBackground ? grey400 : accent600,
            hover: isVeryLowContrastAccentBackground ? grey400 : accent600,
            pressed: isVeryLowContrastAccentBackground ? grey500 : accent800,
          },
          secondary: {
            default: background100,
            disabled: grey200,
            focused: isVeryLowContrastAccentBackground
              ? grey400
              : isBackgroundColorCloseToBlack
                ? accent200T
                : accent100T,
            hover: isVeryLowContrastAccentBackground
              ? grey400
              : isBackgroundColorCloseToBlack
                ? accent200T
                : accent100T,
            pressed: isVeryLowContrastAccentBackground
              ? grey500
              : isBackgroundColorCloseToBlack
                ? accent300T
                : accent200T,
          },
          ghost: {
            default: backgroundTransparent,
            disabled: backgroundTransparent,
            focused: isVeryLowContrastAccentBackground
              ? grey400
              : isBackgroundColorCloseToBlack
                ? accent200T
                : accent100T,
            hover: isVeryLowContrastAccentBackground
              ? grey400
              : isBackgroundColorCloseToBlack
                ? accent200T
                : accent100T,
            pressed: isVeryLowContrastAccentBackground
              ? grey500
              : isBackgroundColorCloseToBlack
                ? accent300T
                : accent200T,
          },
          compact: {
            default: backgroundTransparent,
            disabled: backgroundTransparent,
            focused: backgroundTransparent,
            hover: backgroundTransparent,
            pressed: isVeryLowContrastAccentBackground ? grey500 : accent200T,
          },
        },
        fields: {
          background: accent100T,
          border: grey500,
          disabled: grey400,
          hover: isLowContrastAccentBackground ? accent700 : accent500,
          pressed: isLowContrastAccentBackground ? getFontColor(accent500) : accent500,
        },
        svg: {
          primary: isLowContrastAccentBackground ? getFontColor(background100) : accent500,
          secondary: isLowContrastAccentBackground ? grey500 : accent300T,
        },
        checkbox: {
          svg: isLowContrastAccentBackground ? getFontColor(getFontColor(background100)) : getFontColor(accent500),
          background: {
            unchecked: background100,
            checked: isLowContrastAccentBackground ? getFontColor(background100) : accent500,
          },
          border: {
            default: getFontColor(background100),
            disabled: grey500,
            hover:
              isLowContrastAccentBackground || isLowColorContrast(accent500, getFontColor(background100))
                ? grey600
                : accent500,
            checked: isLowContrastAccentBackground ? getFontColor(background100) : accent500,
          },
        },
        message: {
          background: isLowTextContrast(accent500, background100)
            ? background200
            : isBackgroundColorCloseToBlack
              ? accent200T
              : accent100T,
          text: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
        },
        text: {
          default: getFontColor(background100),
          inverted: getFontColor(getFontColor(background100)),
          selection: getFontColor(grey500),
          selectionBackground: grey500,
          onAccent: getFontColor(accent500),
          onFields: getFontColor(background100),
          onCta: {
            primary: {
              default: getFontColor(accent500),
              disabled: grey600,
              focused: getFontColor(accent500),
              hover: getFontColor(accent500),
              pressed: getFontColor(accent500),
            },
            secondary: {
              default: getFontColor(background100),
              disabled: grey600,
              focused: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
              hover: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
              pressed: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
            },
            ghost: {
              default: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
              disabled: grey600,
              focused: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
              hover: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
              pressed: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
            },
            compact: {
              default: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
              disabled: grey600,
              focused: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
              hover: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
              pressed: isLowTextContrast(accent500, background100) ? getFontColor(background100) : accent500,
            },
          },
        },
      },
      fonts: {
        heading: designKit?.fonts?.heading?.fontName ?? DEFAULT_FONT.fontName,
        body: designKit?.fonts?.body?.fontName ?? DEFAULT_FONT.fontName,
      },
    },
  };
}
