// inspired from:
// - https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js
// - https://github.com/vercel/next.js/blob/canary/examples/api-routes-apollo-server-and-client/apollo/client.js
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Locale } from '@/types/locale';
import { getBackendAcceptLanguage } from './i18n/i18n';
import { CustomPageProps } from 'pages/_app.page';
import fetch from 'cross-fetch';

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

type PageProps = AppProps['pageProps'];

type ServerPageProps = {
  props: PageProps;
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient(endpointUri: string, appVersion: string, locale?: string) {
  const isServer = typeof window === 'undefined';
  const headers = {
    'Accept-Language': getBackendAcceptLanguage(locale as Locale),
    'X-App-Name': isServer ? 'widget-front-ssr' : 'widget-front-browser',
    'X-App-Version': appVersion,
  };
  return new ApolloClient({
    name: isServer ? 'widget-front-ssr' : 'widget-front-browser',
    ssrMode: isServer,
    link: new BatchHttpLink({
      credentials: 'same-origin',
      headers,
      uri: endpointUri,
      fetch,
    }),
    cache: new InMemoryCache({
      typePolicies: {
        WidgetConfiguration: {
          keyFields: ['restaurantUuid'],
        },
      },
    }),
  });
}

function initializeApollo(
  endpointUri: string,
  appVersion: string,
  locale?: string,
  initialState?: NormalizedCacheObject,
) {
  const _apolloClient = apolloClient ?? createApolloClient(endpointUri, appVersion, locale);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  serverPageData: { props: CustomPageProps },
): ServerPageProps {
  if (serverPageData?.props) {
    serverPageData.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return serverPageData;
}

export function useApollo(pageProps: CustomPageProps) {
  const { publicRuntimeConfig } = getConfig();
  const endpointUri = publicRuntimeConfig.publicGraphQlApiUrl;
  const appVersion = publicRuntimeConfig.gitCommit;
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const { locale } = useRouter();
  const store = useMemo(
    () => initializeApollo(endpointUri, appVersion, locale, state),
    [appVersion, endpointUri, locale, state],
  );
  return store;
}

export function getApolloClientForBackend(locale?: string) {
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
  const endpointUri = serverRuntimeConfig.apiGraphQl;
  const appVersion = publicRuntimeConfig.gitCommit;
  return initializeApollo(endpointUri, appVersion, locale);
}
