import { ComponentProps, ReactNode } from 'react';
import ChiliButton from '@lafourchette/react-chili/dist/cjs/components/Atoms/Form/Button';
import * as S from './Button.styles';

type ButtonProps = Omit<ComponentProps<typeof ChiliButton>, 'variant' | 'disabled'> & {
  disabled?: boolean;
  variant?: S.ButtonVariant;
  isActive?: boolean;
  showArrow?: boolean;
  children: ReactNode;
};

export const Button = ({
  variant = 'primary',
  disabled = false,
  isActive = false,
  showArrow = false,
  children,
  ...props
}: ButtonProps) => {
  return (
    <S.StyledButton variant={variant} disabled={disabled} isActive={isActive} {...props}>
      {children}
      {showArrow && <S.IconChevronRight ml="s" />}
    </S.StyledButton>
  );
};
