import ChiliHeading from '@lafourchette/react-chili/dist/cjs/components/Atoms/Heading';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { ComponentProps } from 'react';
import * as S from './Heading.styles';

export type HeadingProps = Omit<ComponentProps<typeof ChiliHeading>, 'variant' | 'disabled'> & {
  variant?: S.HeadingVariant;
};

export const Heading = ({ variant = 'headline', ...props }: HeadingProps) => {
  const { isWidgetCustomizable } = useWidgetConfig();

  if (isWidgetCustomizable) {
    return <S.StyledHeading data-testid="styled-heading" variant={variant} {...props} />;
  }

  return <ChiliHeading data-testid="chili-heading" variant={variant} {...props} />;
};
